<template>
  <div id="vm-cola-payment-failed" class="vm-cola-payment-failed">
    <!-- <div class="payment-failed-container">
			<h1>Ups!<br>Maaf terjadi kendala.</h1>
			<img src="/static/img/vending-machine/payment-failed.svg" alt="">
			<h4>
				Kamu telah berhasil membeli produk di Sociolla Smart Vending Machine ini. Namun, kami mendeteksi bahwa produk kamu tersangkut dan tidak mau keluar
				<br><br>
				Kami memastikan bahwa kamu tetap akan mendapatkan produk yang telah kamu beli. Silahkan segera hubungi customer service di <span>cs@sociolla.com</span> atau ke nomor <span>0821 2454 1956</span>
			</h4>
			<button @click.prevent="cancelOrder(true)">{{ isLogin ? 'LOG OUT' : 'SELESAI'}}</button>
		</div> -->

    <div class="payment-failed-container">
      <h1>sorry, something <br />went wrong! :(</h1>

      <div class="detail-container">
        <table class="detail">
          <tr>
            <td>
              <div class="label">Nomor Pesanan</div>
              <div class="value">{{ reference_code }}</div>
            </td>
            <td>
              <div class="label">Total Pesanan</div>
              <div class="value">{{ total_price | currencyRp }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label">Tanggal Pembayaran</div>
              <div class="value">{{ order_detail.created_at | dateFormat }}</div>
            </td>
            <td>
              <div class="label">Metode Pembayaran</div>
              <div class="value">{{ payment_method }}</div>
            </td>
          </tr>
        </table>
      </div>
      <h4 style="font-size: 30px">
        Mesin sedang ada peningkatan layanan untuk memproses
        <br />
        beauties-mu. But don't worry, kamu akan tetap menerima
        <br />
        produk sesuai pesanan, ya!
      </h4>
      <h4 style="font-size: 35px"><b>Scan QR di bawah ini untuk melanjutkan:</b></h4>
      <img class="qr-cs" src="/static/img/icons/qrcode-customer-service.png" alt="" />
      <br />
      <div class="divider" />
      <button @click.prevent="cancelOrder(true, '/vmcola/home')">{{ isLogin ? 'LOG OUT' : 'Ke Halaman Utama' }}</button>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import autotimeout from '@/mixins/auto-timeout';

export default {
  name: 'vmColaPaymentSuccessFailed',
  mixins: [helpers, autotimeout],

  data() {
    return {
      order_detail: {},
    };
  },

  computed: {
    isLogin() {
      return this.$store.state.QRcode.isLoggedIn;
    },
    reference_code() {
      return this.$route.query.reference_code;
    },
    total_price() {
      return this.$route.query.total;
    },
    payment_method() {
      return this.$route.query.payment_method;
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_GLOBAL_LOADING', false);
    this.$store.commit('point_applied', 0);
    this.$store.commit('PRODUCT_LIST', []);
  },

  mounted() {
    this.getOrderDetail();
  },

  methods: {
    async getOrderDetail() {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const id_order = this.$route.query.id_order;
      if (loc && id_order) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        await axios
          .get(`${Config.MS_ORDER_API_URL}/order/${id_order}`, {
            headers: headers,
          })
          .then((res) => {
            if (res.data.data) {
              this.order_detail = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'payment-failed';
</style>
